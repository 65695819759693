<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="400px" v-model="dialogShareIssue">
      <v-card>
        <v-toolbar dense dark color="primary" class="elevation-0">
          <div v-text="'Share Issue List'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogShareIssueEmit(1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0 pt-4">
          <v-form ref="form">
            <v-text-field
              dense
              multiple
              outlined
              class="FontSize borderRadius field_height field_label_size"
              label="Enter Email ID"
              v-model="to_email_id"
              :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn small outlined color="primary" @click="dialogShareIssueEmit(1)">Cancel</v-btn>
          <v-btn small color="primary" :loading="loading" @click="validateFormMethod()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { convertIssueDataToExcel } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogShareIssue: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    loading: false,
    to_email_id: "",
    SnackBarComponent: {},
  }),
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.GetDownloadedExcelMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Select An Email ID",
        };
      }
    },
    async GetDownloadedExcelMethod() {
      var mutationname = convertIssueDataToExcel;
      //   var mutationresult = "ConvertIssueDataToExcel";
      var inputParams = {
        project_id: this.$store.getters.get_current_project_details.project_id,
        to_email_id: this.to_email_id,
        user_email_id:this.$store.getters.get_user_email,
      };
      try {
        this.loading = true;
        await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        // var ResultObject = JSON.parse(result.data[mutationresult]);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Issue List Shared Successfully!!",
        };
        this.loading = false;
        this.dialogShareIssueEmit((this.Toggle = 2));
      } catch (error) {
        console.log("fetch The error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogShareIssueEmit(Toggle) {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
