export const HasActionAccess = {
  data() {
    return {};
  },
  methods: {
    checkAccess() {
      let project_details = this.$store.getters.get_current_project_details;
      switch (this.$route.name) {
        case "Testers":
          if (project_details.tester_lead_email_id == this.$store.getters.get_user_email || project_details.created_by == this.$store.getters.get_user_email) return true;
          else return true;
        case "Developers":
          if (project_details.developer_lead_email_id == this.$store.getters.get_user_email || project_details.created_by == this.$store.getters.get_user_email) return true;
          else return true;
        case "Observers":
          if (project_details.created_by == this.$store.getters.get_user_email) return true;
          else return true;
        case "Issues":
          if (project_details.tester_lead_email_id == this.$store.getters.get_user_email || project_details.testers_email.includes(this.$store.getters.get_user_email)) return true;
          else return true;
      }
    },
  },
};
