<template>
  <div>
    <Overlay :overlay="overlay" />
    <CreateIssueDialog :dialogCreateIssue="dialogCreateIssue" :StoreObj="StoreObj" @clicked="dialogCreateIssueEmit" />
    <ChangeIssuesStatusDialog
      v-if="dialogChangeIssuesStatus == true"
      :dialogChangeIssuesStatus="dialogChangeIssuesStatus"
      :issue_status="issue_status"
      @clicked="dialogChangeIssuesStatusEmit"
      :StoreObj="StoreObj"
    />
    <ShareIssueListDialog :dialogShareIssue="dialogShareIssue" @clicked="dialogShareIssueEmit" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="4">
          <v-toolbar dense class="elevation-0">
            <v-toolbar-title class="font-weight-bold"
              >Bugs/ Enhancements <span class="FontSize"> ({{ issue_status == "ALL" ? "ALL/ ACKNOWLEDGED" : issue_status }})</span></v-toolbar-title
            >
            <v-btn small icon color="primary" class="ml-3" @click="GetAllIssuesDetailsMethod(issue_status)">
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
  <v-col cols="12" xs="12" sm="12" md="5">
          <v-card-actions class="px-0 pb-0">
            <v-text-field dense outlined class="FontSize borderRadius field_height field_label_size mt-2" append-icon="mdi-magnify" label="Search" v-model="search"></v-text-field>
            <v-select
              dense
              outlined
              class="FontSize borderRadius field_height maxWidth field_label_size mt-2 ml-2"
              label="Issue Status"
              :items="issue_statusItems"
              v-model="issue_status"
              item-text="text"
              item-value="value"
            ></v-select>
          </v-card-actions>
        </v-col>      
        <v-col cols="12" xs="12" sm="12" md="3">
          <v-card-actions class="px-0 pb-0">
            <v-select
              dense
              outlined
              class="FontSize borderRadius field_height field_label_size mt-2 ml-2"
              label="Sort By"
              :items="sort_by_items"
              v-model="sort_by"
              item-text="text"
              item-value="value"
            ></v-select>
            <v-menu offset-y v-if="GetAllIssuesDetailsList.length != 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :loading="loading" small class="text-capitalize mt-n5 mx-2" color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon small>mdi-file-excel</v-icon>
                  Excel
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, index) in items" :key="index" @click="checkItemMenu(item.value)">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn v-else small color="primary" :disabled="GetAllIssuesDetailsList.length == 0" class="text-capitalize mt-n5 ml-2">
              <v-icon small>mdi-file-excel</v-icon>
              Excel</v-btn
            >
            <!-- <v-btn v-if="checkAccess() == true" small color="primary" class="text-capitalize mt-n5" @click="(dialogCreateIssue = true), (StoreObj.action = 'CREATE')">Report Issue</v-btn> -->
          </v-card-actions>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12">
          <!-- issue_status == 'UNACKNOWLEDGED' || issue_status == 'ACKNOWLEDGED'
                ? issuesHeader.filter((header) => header.text != 'Assigned To' && header.text != 'Status')
                : issue_status == 'ASSIGNED' || issue_status == 'FIXED' || issue_status == 'CLOSED'
                ? issuesHeader.filter((header) => header.text != 'Status')
                : issuesHeader -->
          <v-data-table
            dense
            :fixed-header="true"
            :height="windowHeight"
            class="elevation-0"
            :headers="issuesHeader"
            :footer-props="{
              'items-per-page-options': [50, 100, 250, 500, 1000],
            }"
            :items-per-page="50"
            :items="GetAllIssuesDetailsList"
            :search="search"
            @click:row="changeIssueStatus"
            :no-data-text="noDataText"
          >
            <template v-slot:[`item.issue_id`]="{ item }">
              <div class="FontSize">
                <v-icon small color="primary" v-text="item.issue_type == 'BUG' ? 'mdi-bug' : 'mdi-receipt-text-plus'"></v-icon>
                {{ item.issue_id }}
              </div>
            </template>
            <template v-slot:[`item.issue_type`]="{ item }">
              <div class="FontSize">{{ item.issue_type }}</div>
            </template>
            <template v-slot:[`item.issue_reported_by`]="{ item }">
              <div class="FontSize">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      v-text="item.is_externally_reported_issue == true ? 'mdi-book-arrow-right-outline' : 'mdi-file-document'"
                      small
                      color="primary"
                      class="mr-2"
                    ></v-icon>
                  </template>
                  <span v-text="item.is_externally_reported_issue == true ? 'External Form' : 'Internal Form'"></span>
                </v-tooltip>
                {{ item.issue_reported_by }}<span class="font-weight-bold" v-if="$store.getters.get_user_email == item.issue_reported_by">(Me)</span>
              </div>
            </template>
            <template v-slot:[`item.Ageing`]="{ item }">
              <div class="FontSize">{{ checkTime(item.issue_reported_on) }}</div>
              <div class="fontSizeSmall font-weight-bold">Last Updated {{ checkTime(item.last_updated_on) }}</div>
            </template>
            <template v-slot:[`item.issue_reported_platform`]="{ item }">
              <div class="FontSize">{{ item.issue_reported_platform ? item.issue_reported_platform.replaceAll("_", " ") : "-" }}</div>
            </template>
            <template v-slot:[`item.issue_module_name`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="FontSize" v-bind="attrs" v-on="on">{{item.issue_module_name? item.issue_module_name.length > 20 ? `${item.issue_module_name.slice(0, 20)}..` : `${item.issue_module_name}`:'-'}}</span>
                </template>
                <span>{{ item.issue_module_name?item.issue_module_name:'-'}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.issue_severity`]="{ item }">
              <div class="FontSize" :class="item.issue_severity == 'HIGH' ? 'red--text' : item.issue_severity == 'MEDIUM' ? 'deepYellowClass' : 'blue--text'">
                {{ item.issue_severity ? item.issue_severity.replaceAll("_", " ") : "-" }} <span class="black--text font-weight-bold" v-if="item.is_reopen == true">(RE-OPENED)</span>
              </div>
            </template>
            <template v-slot:[`item.issue_assigned_to`]="{ item }">
              <div class="FontSize">
                {{ item.issue_assigned_to ? item.issue_assigned_to : "-" }} <span class="font-weight-bold" v-if="$store.getters.get_user_email == item.issue_assigned_to">(Me)</span>
              </div>
            </template>
            <template v-slot:[`item.issue_status`]="{ item }">
              <div class="FontSize">
                {{ item.issue_status.replaceAll("_", " ") }}
                <span class="black--text font-weight-bold" v-if="item.is_reopen == true">(RE-OPENED)</span>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { HasActionAccess } from "@/mixins/HasActionAccess.js";
import { GetIssueStatusFilteredByUser } from "@/mixins/GetIssueStatusFilteredByUser.js";
import { GetAllIssuesDetails } from "@/mixins/GetAllIssuesDetails.js";
import { GetDownloadedExcel } from "@/mixins/GetDownloadedExcel.js";
import CreateIssueDialog from "@/components/Issues/Dialogs/CreateIssueDialog.vue";
import ChangeIssuesStatusDialog from "@/components/Extras/ChangeIssuesStatusDialog.vue";
import ShareIssueListDialog from "@/components/Issues/Dialogs/ShareIssueListDialog.vue";
import { GetEachProjectDetails } from "@/mixins/GetEachProjectDetails.js";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  components: {
    ChangeIssuesStatusDialog,
    ShareIssueListDialog,
    CreateIssueDialog,
    Overlay,
  },
  mixins: [HasActionAccess, GetAllIssuesDetails, GetIssueStatusFilteredByUser, GetDownloadedExcel, GetEachProjectDetails],
  data: () => ({
    search: "",
    overlay: false,
    loading: false,
    windowHeight: 0,
    dialogShareIssue: false,
    dialogCreateIssue: false,
    dialogDeleteIssue: false,
    dialogChangeIssuesStatus: false,
    StoreObj: {},
    issuesHeader: [
      {
        text: "Issue ID",
        value: "issue_id",
      },
      {
        text: "Title",
        value: "issue_module_name",
      },
      {
        text: "Ageing",
        value: "Ageing",
      },
      {
        text: "Reported By",
        value: "issue_reported_by",
      },
      {
        text: "Sub-System",
        value: "issue_reported_platform",
      },
      {
        text: "Pending On",
        value: "issue_assigned_to",
      },
      {
        text: "Severity",
        value: "issue_severity",
      },
      {
        text: "Status",
        value: "issue_status",
      },
    ],
    issuesItems: [],
    issue_status: "",
    issue_statusItems: [
    {
        text: "UNACKNOWLEDGED",
        value: "UNACKNOWLEDGED",
      },
      {
        text: "ALL",
        value: "ALL",
      },
      {
        text: "ASSIGNED",
        value: "ASSIGNED",
      },
      {
        text: "FIXED",
        value: "FIXED",
      },
      {
        text: "CLOSED",
        value: "CLOSED",
      },
      {
        text: "REOPENED",
        value: "REOPENED",
      },
      {
        text: "REJECTED",
        value: "REJECTED",
      },
   
    
    ],
    items: [
      { title: "Download", value: "Download" },
      { title: "Share", value: "Share" },
    ],
    sort_by_items: [
      { text: "Most Recent", value: "MOST_RECENT" },
      { text: "Most Aged", value: "MOST_AGED" },
    { text: "Last Updated", value: "LAST_UPDATED_ON" },
    ],
    sort_by: "MOST_RECENT",
  }),
  watch: {
    sort_by(value) {
      if (value && value.length != "") {
        this.filterItems();
      }
    },
    getEachProjectDetails(value) {
      this.editStatus(value);
    },
    issue_status() {
      let project_details = this.$store.getters.get_current_project_details;
      this.editStatus(project_details);
      this.$forceUpdate();
      this.GetAllIssuesDetailsMethod(this.issue_status);
    },
    "$store.getters.get_refresh"(value) {
      console.log("Check value", value);
      if (value == true) {
        this.GetAllIssuesDetailsMethod(this.issue_status);
      }
    },
  },
  mounted() {
    this.issue_status = "ALL";
    this.windowHeight = window.innerHeight - 200;
  },
  methods: {
    filterItems() {
      switch (this.sort_by) {
        case "MOST_RECENT":
          this.GetAllIssuesDetailsList = this.GetAllIssuesDetailsList.sort(function (x, y) {
            return new Date(y.issue_reported_on).getTime() - new Date(x.issue_reported_on).getTime();
          });
          break;
        case "MOST_AGED":
          this.GetAllIssuesDetailsList = this.GetAllIssuesDetailsList.sort(function (x, y) {
            return new Date(x.issue_reported_on).getTime() - new Date(y.issue_reported_on).getTime();
          });
          break;
          case "LAST_UPDATED_ON":
          this.GetAllIssuesDetailsList = this.GetAllIssuesDetailsList.sort(function (x, y) {
            return new Date(y.last_updated_on).getTime() - new Date(x.last_updated_on).getTime();
          });
            break
          // this.GetAllIssuesDetailsList = this.GetAllIssuesDetailsList.sort(function (x, y) {
          //   return new Date(x.issue_reported_on).getTime() ;
          // });
      }
    },
    editStatus(project_details) {
      this.issue_statusItems[0].text = `UNACKNOWLEDGED (${project_details.no_of_unacknowledged_issues})`;
      this.issue_statusItems[1].text = `ALL (${project_details.no_of_assigned_issues+project_details.no_of_unacknowledged_issues+project_details.no_of_fixed_issues+project_details.no_of_closed_issues+project_details.no_of_rejected_issues})`;
      this.issue_statusItems[2].text = `ASSIGNED (${project_details.no_of_assigned_issues})`;
      this.issue_statusItems[3].text = `FIXED (${project_details.no_of_fixed_issues})`;
      this.issue_statusItems[4].text = `CLOSED (${project_details.no_of_closed_issues})`;
      this.issue_statusItems[5].text = `REOPENED (${project_details.no_of_reopen_issues})`;
      this.issue_statusItems[6].text = `REJECTED (${project_details.no_of_rejected_issues})`;
  
     
    },
    checkTime(second) {
      var seconds = (new Date().getTime() - new Date(second).getTime()) / 1000;
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? " Day Ago" : " Days Ago") : "";
      var hDisplay = h > 0 ? h + (h == 1 ? " Hour Ago" : " Hours Ago") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " Minute Ago" : " Minutes Ago") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " Second Ago" : " Seconds Ago") : "";
      if (dDisplay != "") return dDisplay;
      else if (hDisplay != "") return hDisplay;
      else if (mDisplay != "") return mDisplay;
      else if (sDisplay) return sDisplay;
    },
    dialogShareIssueEmit() {
      this.dialogShareIssue = false;
    },
    checkItemMenu(action) {
      switch (action) {
        case "Download":
          this.GetDownloadedExcelMethod(action);
          break;
        case "Share":
          this.dialogShareIssue = true;
          break;
      }
    },

    dialogChangeIssuesStatusEmit(Toggle) {
      this.dialogChangeIssuesStatus = false;
      if (Toggle == 2) {
        this.GetAllIssuesDetailsMethod(this.issue_status);
        this.GetEachProjectDetailsMethod();
      }
    },
    changeIssueStatus(item) {
      this.StoreObj = item;
      this.dialogChangeIssuesStatus = true;
    },
    dialogDeleteIssueEmit() {
      this.dialogDeleteIssue = false;
    },
    checkItem(item) {
      this.StoreObj = item;
      this.dialogDeleteIssue = true;
    },
    dialogCreateIssueEmit(Toggle) {
      this.dialogCreateIssue = false;
      if (Toggle == 2) {
        this.GetAllIssuesDetailsMethod(this.issue_status);
        this.GetEachProjectDetailsMethod();
      }
    },
  },
};
</script>
