export const GetIssueStatusFilteredByUser = {
  data() {
    return {};
  },
  methods: {
    async getStatusForIssue() {
      let project_details = await this.$store.getters.get_current_project_details;
      if (project_details.tester_lead_email_id == this.$store.getters.get_user_email && project_details.developer_lead_email_id == this.$store.getters.get_user_email) {
        this.issue_statusItems = ["UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
        this.pass_user_email = false;
      } else if (project_details.tester_lead_email_id == this.$store.getters.get_user_email && project_details.developer_lead_email_id != this.$store.getters.get_user_email) {
        this.issue_statusItems = ["UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
        this.pass_user_email = false;
      } else if (project_details.tester_lead_email_id != this.$store.getters.get_user_email && project_details.developer_lead_email_id == this.$store.getters.get_user_email) {
        this.issue_statusItems = ["UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
        this.pass_user_email = false;
      } else if (project_details.tester_lead_email_id != this.$store.getters.get_user_email && project_details.developer_lead_email_id != this.$store.getters.get_user_email) {
        if (project_details.testers_email.includes(this.$store.getters.get_user_email) == true && project_details.developers_email.includes(this.$store.getters.get_user_email) == true) {
          this.issue_statusItems = ["UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
        } else if (project_details.testers_email.includes(this.$store.getters.get_user_email) == true && project_details.developers_email.includes(this.$store.getters.get_user_email) == false) {
          this.issue_statusItems = ["UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
        } else if (project_details.testers_email.includes(this.$store.getters.get_user_email) == true && project_details.developers_email.includes(this.$store.getters.get_user_email) == true) {
          this.issue_statusItems = ["UNACKNOWLEDGED", "ACKNOWLEDGED", "ASSIGNED", "FIXED", "CLOSED", "REOPENED"];
        }
        this.pass_user_email = true;
      }
      this.issue_status = this.issue_statusItems[0];
      this.GetAllIssuesDetailsMethod(this.issue_status);
      this.$forceUpdate();
    },
  },
};
