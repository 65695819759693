import { convertIssueDataToExcel } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetDownloadedExcel = {
  data() {
    return {
      noDataText: "",
      GetDownloadedExcelList: [],
      overlay: false,
    };
  },
  methods: {
    async GetDownloadedExcelMethod(action,to_email_id) {
      var mutationname = convertIssueDataToExcel;
      var mutationresult = "ConvertIssueDataToExcel";
      var inputParams = {
        project_id: this.$store.getters.get_current_project_details.project_id,
        to_email_id:action=='Download'?undefined:to_email_id,
        user_email_id: this.$store.getters.get_user_email,
       

      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        window.open(ResultObject)
        this.loading = false;
      } catch (error) {
        console.log("fetch The error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
         
        };
        this.loading = false;
      }
    },
  },
};
